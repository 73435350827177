import React, {useState, useEffect} from 'react'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import { format } from 'date-fns';
import {confirmAlert} from 'react-confirm-alert'
import {useForm} from 'react-hook-form'
import useEventsHook from '../../../api/events'
// import {ErrorBoundary} from "react-error-boundary";
// import {useNavigate} from 'react-router-dom';
import { ViewEvents } from '../../../components/admin/events/ViewEvents';
import { FormEvents } from '../../../components/admin/events/FormEvents';
import {
    Spinner,
    Pagination,
    Message,
    Confirm,
} from '../../../components'
// import {Button} from "@mui/material";

const Events = () => {
    const [page, setPage] = useState(1)
    const [id, setId] = useState(null)
    const [edit, setEdit] = useState(false)
    const [q, setQ] = useState('')

    const {getEvents, postEvent, updateEvent, deleteEvent} = useEventsHook({
        page,
        q,
    })

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            confirmed: true,
            blocked: false,
        },
    })

    const {
        data,
        isLoading,
        isError,
        error,
        refetch
    } = getEvents

    const {
        isLoading: isLoadingUpdate,
        isError: isErrorUpdate,
        error: errorUpdate,
        isSuccess: isSuccessUpdate,
        mutateAsync: mutateAsyncUpdate,
    } = updateEvent

    const {
        isLoading: isLoadingDelete,
        isError: isErrorDelete,
        error: errorDelete,
        isSuccess: isSuccessDelete,
        mutateAsync: mutateAsyncDelete,
    } = deleteEvent

    const {
        isLoading: isLoadingPost,
        isError: isErrorPost,
        error: errorPost,
        isSuccess: isSuccessPost,
        mutateAsync: mutateAsyncPost,
    } = postEvent

    const formCleanHandler = () => {
        if (isSuccessPost || isSuccessUpdate) {
            setEdit(false);
            reset();
        }
    }

    useEffect(() => {
        //if (isSuccessPost || isSuccessUpdate) formCleanHandler()
    }, [isSuccessPost, isSuccessUpdate])

    useEffect(() => {
        refetch()
    }, [page])

    useEffect(() => {
        if (!q) refetch()
    }, [q])

    const searchHandler = (e) => {
        e.preventDefault()
        refetch()
        setPage(1)
    }

    const deleteHandler = (id) => {
        confirmAlert(Confirm(() => mutateAsyncDelete(id)))
    }

    const submitHandler = async (data) => {
        try {
            // console.log(edit);
            if (edit) {
                await mutateAsyncUpdate({
                    _id: id,
                    title: data.title,
                    address: data.address,
                    description: data.description,
                    glocation: data.glocation,
                    link: data.link,
                    // date: data.date,
                    dateStart: data.dateStart,
                    dateEnd: data.dateEnd,
                    time: data.time,
                    image: data.image,
                    category: data.category,
                    tags: data.tags,
                    email: data.email,
                    confirmed: data.confirmed,
                    blocked: data.blocked
                });
            } else {
                await mutateAsyncPost(data);
            }

            // Request succeeded
            if (edit) {
                console.log('SUCCESSFULL EDIT POST');
            } else {
                console.log('SUCCESSFULL NEW POST');
            }
        } catch (error) {
            // Request failed
            console.error(error);
            if (edit) {
                console.log('FAILED EDIT POST')

            } else {
                console.log('FAILED NEW POST')
            }
            if (error.response && error.response.status === 500) {
                console.log('FAILED POST 500')
            }
        }
    };

    const editHandler = (event) => {


        console.log(event)
        const dateStart = Date.parse(event.dateStart);
        const formattedDateStart = isNaN(dateStart) ? null : format(new Date(dateStart), 'yyyy-MM-dd');

        const dateEnd = Date.parse(event.dateEnd);
        const formattedDateEnd = isNaN(dateEnd) ? null : format(new Date(dateEnd), 'yyyy-MM-dd');

        // console.log(formattedDateStart)
        // console.log(formattedDateEnd)

        setId(event._id)
        setEdit(true)
        setValue('title', event.title)
        setValue('address', event.address)
        setValue('description', event.description)
        setValue('glocation', event.glocation)
        setValue('link', event.link)
        // setValue('date', event.date)
        setValue('dateStart', formattedDateStart)
        setValue('dateEnd', formattedDateEnd)
        setValue('time', event.time)
        setValue('image', event.image)
        setValue('category', event.category)
        setValue('tags', event.tags)
        setValue('email', event.email)
        setValue('confirmed', event.confirmed)
        setValue('blocked', event.blocked)
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Events</title>
                    <meta property='og:title' content='Events' key='title'/>
                </Helmet>
            </HelmetProvider>
            {/*<ErrorBoundary*/}
            {/*    FallbackComponent={() => (*/}
            {/*        <ErrorFallback goBack={() => navigate(-1)} error={isSuccessPost}/>*/}
            {/*    )}*/}
            {/*    onError={console.error}*/}
            {/*>*/}
                {isErrorPost && <Message variant='danger'>{errorPost}</Message>}
                {isErrorUpdate && <Message variant='danger'>{errorUpdate}</Message>}

                {isSuccessDelete && (
                    <Message variant='success'>Event has been deleted successfully.</Message>
                )}
                {isErrorDelete && <Message variant='danger'>{errorDelete}</Message>}
                {isSuccessUpdate && (
                    <Message variant='success'>Event has been updated successfully.</Message>
                )}
                {isSuccessPost && (
                    <Message variant='success'>Event has been Created successfully.</Message>
                )}

                <FormEvents
                    edit={edit}
                    formCleanHandler={formCleanHandler}
                    isLoading={isLoading}
                    setValue={setValue}
                    isError={isError}
                    errors={errors}
                    isLoadingUpdate={isLoadingUpdate}
                    isLoadingPost={isLoadingPost}
                    register={register}
                    handleSubmit={handleSubmit}
                    submitHandler={submitHandler}
                    watch={watch}
                    error={error}
                />

                <div className='ms-auto text-end'>
                    <Pagination data={data} setPage={setPage}/>
                </div>

                {isLoading ? (
                    <Spinner/>
                ) : isError ? (
                    <Message variant='danger'>{error}</Message>
                ) : (
                    <ViewEvents
                        data={data}
                        editHandler={editHandler}
                        setEdit={setEdit}
                        formCleanHandler={formCleanHandler}
                        deleteHandler={deleteHandler}
                        isLoadingDelete={isLoadingDelete}
                        setQ={setQ}
                        q={q}
                        searchHandler={searchHandler}
                    />
                )}
            {/*</ErrorBoundary>*/}

        </>
    )
}

// function ErrorFallback({error, goBack}) {
//     const errorMessage = error && error.message ? error.message : "Unknown error";
//
//     return (
//         <div>
//             <div className="user-error-message-block">
//                 <p>Something went wrong 😭</p>
//                 <span>Error: {errorMessage}</span>
//                 <Button onClick={goBack}>Go Back</Button>
//             </div>
//         </div>
//     );
// }

export default Events
