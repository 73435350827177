// /* global dataLayer */
import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import {GoogleTagsKey} from "../../config/client-config";
import "../../css/global-popup.css";



const Footer = () => {
  // const date = new Date()
  // const currentYear = date.getFullYear()

    useEffect(() => {
        ReactGA.initialize(GoogleTagsKey);
        ReactGA.send({
            page: window.location.pathname
        });

    // If you want to track events, uncomment the following lines
    // ReactGA.event({
        //     category: "category",
        //     action: "action",
        //     label: "label", // optional
        //     value: 99, // optional, must be a number
        //     nonInteraction: true, // optional, true/false
        //     transport: "xhr", // optional, beacon/xhr/image
        // });

    }, []);

  return (
    <footer className='text-primary container-fluid'>
      <div className='row'>
        <div className='col text-center py-1 footer font-monospace bg-light my-auto'>
            <div className='footer-copyrights'>
                © {new Date().getFullYear()} ARTNET - v0.65 | Licensed under CC.
            </div>
            <div className='footer-developed-by'>
            {/* Copyright {currentYear} &copy; All Rights Reserved -  */}
              Developed by{' '}
              <a target='_blank' className='developed-by-link' href='https://paradoxos.gr' rel='noreferrer'>
                  paradoxos.gr
              </a>
              <br />
             {/*<img src='/artnet_logo.gif' width='50' height='50' alt='logo' />*/}
            </div>
        </div>
      </div>
    <Popup />
    </footer>
  )
}

const Popup = () => {
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const hasClosedPopup = sessionStorage.getItem('hasClosedPopup');
        const timer = setTimeout(() => {
            if (!hasClosedPopup) {
                setShowPopup(true);
            }
        }, 10000);

        return () => clearTimeout(timer);
    }, []);

    const handleOkClick = () => {
        sessionStorage.setItem('hasClosedPopup', 'true');
        setShowPopup(false);
    };

    if (showPopup) {
        return (
            <div className="global-popup">
                <div className="popup-content">
                    <h2>ArtNet Initiative</h2>
                    <p>As a Non-Profit project we are doing this for free to support the artistic community of Thessaloniki.</p>
                    <p> We sincerely invite your valuable help and feedback.</p>
                    <button onClick={handleOkClick}>OK</button>
                </div>
            </div>
        );
    }
    return null;
};

export default Footer
