import moment from 'moment'
import { FaPenAlt, FaTrash, FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import { Search } from '../../index'
const DefaultMarkerIcon = window.location.origin + "/media/map_icons/default.svg";


const ViewSpaces = ({
  data,
  editHandler,
  deleteHandler,
  formCleanHandler,
  isLoadingDelete,
  setQ,
  q,
  searchHandler,
}) => {
  // console.log(data);
  return (
    <div className='table-responsive bg-light p-3 mt-2'>
      <div className='d-flex align-items-center flex-column mb-2'>
        <h3 className='fw-light text-muted'>
          Spaces List <sup className='fs-6'> [{data && data.total}] </sup>
          <img className="space-list-title-icon" src={DefaultMarkerIcon} alt="Default Marker Icon" />
        </h3>
        <button
          className='btn btn-outline-primary btn-sm shadow my-2'
          data-bs-toggle='modal'
          data-bs-target='#spaceModal'
          onClick={() => formCleanHandler()}
        >
          Add New Space
        </button>
        <div className='col-auto'>
          <Search
            placeholder='Search by Name,Address,Category'
            setQ={setQ}
            q={q}
            searchHandler={searchHandler}
          />
        </div>
      </div>
      <table className='table table-sm table-border edit-grid-view'>
        <thead className='border-0'>
          <tr>
            <th>Joined Date</th>
            <th>Name</th>
            <th>Address</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Category</th>
            <th>Tags</th>
            <th>Confirmed</th>
            <th>Blocked</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
        {data && data.data ? (
            data.data.map((space) => (
              <tr key={space._id} className={(space.name === '' || space.lng === '' || space.lat === '') ? 'empty-values' : ''}>
                <td>{moment(space.createdAt).format('lll')}</td>
                <td>{space.name}</td>
                <td>{space.address}</td>

                <td>{space.phone}</td>
                <td>
                  <a href={`mailto:${space.email}`}>{space.email}</a>
                </td>
                <td>{space.category}</td>
                <td>{space.tags}</td>

                <td>
                  {space.confirmed ? (
                    <FaCheckCircle className='text-success' />
                  ) : (
                    <FaTimesCircle className='text-danger' />
                  )}
                </td>
                <td>
                  {space.blocked ? (
                    <FaCheckCircle className='text-success' />
                  ) : (
                    <FaTimesCircle className='text-danger' />
                  )}
                </td>

                <td>
                  <div className='btn-group'>
                    <button
                      className='btn btn-primary btn-sm rounded-pill'
                      onClick={() => editHandler(space)}
                      data-bs-toggle='modal'
                      data-bs-target='#spaceModal'
                    >
                      <FaPenAlt />
                    </button>

                    <button
                      className='btn btn-danger btn-sm ms-1 rounded-pill'
                      onClick={() => deleteHandler(space._id)}
                      disabled={isLoadingDelete}
                    >
                      {isLoadingDelete ? (
                        <span className='spinner-border spinner-border-sm' />
                      ) : (
                        <span>
                          <FaTrash />
                        </span>
                      )}
                    </button>
                  </div>
                </td>
              </tr>
            ))  ) : (
            // Render an error message when data is undefined or has no data
            <tr>
              <td colSpan="8">Error: Failed to fetch space data</td>
            </tr>
        )}
        </tbody>
      </table>
    </div>
  )
}

export default ViewSpaces
