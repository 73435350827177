import axios from 'axios';
import { config } from '../utils/customLocalStorage';

const apiUrl = process.env.REACT_APP_API_URL || "https://api.aart.gr";

const dynamicAPI = async (method, url, obj = {}) => {

  try {

    switch (method) {
      case 'get':
        return await axios
          .get(`${apiUrl}${url}`, config())
          .then((res) => res.data)

      case 'post':
        return await axios
          .post(`${apiUrl}${url}`, obj, config())
          .then((res) => res.data)

      case 'put':
        return await axios
          .put(`${apiUrl}${url}`, obj, config())
          .then((res) => res.data)

      case 'delete':
        return await axios
          .delete(`${apiUrl}${url}`, config())
          .then((res) => res.data)
    }
  } catch (error) {
    if (error.response) {
      // The server responded with an error
      // console.error(error.response)

      console.error('SERVER ERROR:' + error.response.data.error)
      // throw new Error(error.response)
      // console.error(error)


      if (error.response.status === 401) {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('userRole');

        // window.location.reload();
        // setAuth(null);

        // We pass it only in cases we want to redirect the user, for example on permissions page we force the user but on users edit we don't want to lose the data"
        // if (navigate) {
        //     // navigate('/auth/login');
        // }
      }


      return {
        error: error.response.data.error,
        isError : true,
        isSuccess : false
      };
      // return {
      //   error: error.response.data.error,
      //   obj: obj
      // };
    } else if (error.message) {
      // There was a general error
      console.error('General ERROR:' + error.message)
      // throw new Error(error.message)
      return error.message;

    } else {
      // Something else went wrong
      console.error('An unexpected error occurred.')
      return 'An unexpected error occurred.';
      // throw new Error('An unexpected error occurred.')
    }
    //throw error.response.data.error
  }
}

export default dynamicAPI
