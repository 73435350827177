import React, {useState, useRef} from "react";
import {useJsApiLoader, Autocomplete} from "@react-google-maps/api";
import "../css/locationHelper.css";
import {GoogleMapsAPIKey} from "../config/client-config";

const googleMapLibrariesForLinker = ["drawing", "places", "geometry"];


export const locationLinker = (args) => {
    const {register, setValue, label, watch, name, isRequired = false} = args;
    const [isLocationLinkerClicked, setIsLocationLinkerClicked] = useState(false);

    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: GoogleMapsAPIKey,
        libraries: googleMapLibrariesForLinker,
    });

    // const [selectedPlace, setSelectedPlace] = useState(null);
    const autocompleteRef = useRef(null); // Create a ref to store the Autocomplete instance

    const handlePlaceSelect = (place) => {
        // Generate the sharing link using place details
        const sharingLink = `https://www.google.com/maps/search/?api=1&query=${place.name}+${place.formatted_address}`;
        console.log(sharingLink);
        // setSelectedPlace(place);
        document.querySelector('input[name="glocation"]').value = sharingLink; // Set the value of glocation input
        setValue(name, sharingLink, { shouldValidate: true });
        setIsLocationLinkerClicked(false);
    };

    const handleLocationLinkChange = (event) => {
        console.log('TRIGGERED: ' + event.target.value);
        setValue('glocation', event.target.value, { shouldValidate: true });
    };

    let currentValue = watch(name);

    return (
        <div className='location-linker-box'>

            <div className='mb-3 location-linker' onClick={() => setIsLocationLinkerClicked(true)}>
                <label htmlFor={'glocation'}>{label}</label>
                <input
                    {...register('glocation', isRequired && {required: `${label} is required`})}
                    type="text"
                    value={currentValue}
                    onChange={handleLocationLinkChange}
                    placeholder="Location Link"
                    style={{width: "100%", padding: "0.5rem", marginTop: "1rem"}}
                    className='form-control'
                    id='glocation'
                />
            </div>
            {isLocationLinkerClicked && (

            <div className='location-linker-autocomplete'>
                {isLoaded && (

                    <Autocomplete
                        onLoad={(autocomplete) => {
                            autocompleteRef.current = autocomplete; // Store the Autocomplete instance in the ref
                            // console.log("Autocomplete loaded:", autocomplete);
                        }}
                        onPlaceChanged={() => {
                            const place = autocompleteRef.current.getPlace(); // Use the ref to get place details
                            handlePlaceSelect(place);
                        }}
                        options={{
                            componentRestrictions: {country: 'gr'}
                        }}
                    >
                        <div className='mb-3'>
                            <input
                                name="find_a_place"
                                type="text"
                                placeholder="Find a place"
                                style={{width: "100%", padding: "0.5rem"}}
                                className='form-control'
                                id='find_a_place'
                            />
                        </div>
                    </Autocomplete>
                )}
            </div>
            )}
        </div>
    );
};


export default locationLinker;




