import {useEffect} from 'react'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import {FormContainer, Message} from '../../components'
import {useForm} from 'react-hook-form'
import useAuthHook from '../../api/auth'
import useUserRolesHook from '../../api/userRoles'
import {inputEmail, inputPassword} from '../../utils/dynamicForm'
import useAuth from '../../hooks/useAuth'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import { useTranslation } from 'react-i18next';

const Login = () => {
    const { t } = useTranslation();

    let [searchParams] = useSearchParams()
    const pathName = searchParams.get('next') || '/'
    const navigate = useNavigate()

    const {auth} = useAuth()
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm()

    const {setAuth} = useAuth()

    const {postLogin} = useAuthHook()
    const {postUserRoleById} = useUserRolesHook(
        {
            page: 1,
            q: '',
            limit: 10000000,
        })

    const {
        mutateAsync,
        isLoading,
        isError: isLoginError,
        error,
        isSuccess,
        data
    } = postLogin

    const {
        mutateAsync: userRoleMutateAsync,
        error: errorUserRole,
        isError: isErrorUserRole,
    } = postUserRoleById

    useEffect(() => {
        console.log(isLoginError)
        if (isSuccess) {
            if (data) {
                if (data._id) {


                    console.log('data._id')
                    console.log(data)
                    userRoleMutateAsync(data._id)
                        .then(userRole => {
                            if (userRole && userRole.clientPermission) {
                                localStorage.setItem('userRole', JSON.stringify(userRole))
                                localStorage.setItem('userInfo', JSON.stringify(data))

                                setAuth({
                                    userInfo: data,
                                    userRole: userRole,
                                })

                                navigate(pathName)
                            } else {
                                console.error('Invalid user role:', userRole)
                            }
                        })
                        .catch(error => {
                            console.error('Error fetching user role:', error)
                        })
                } else {
                    console.error('Invalid data:', data)
                }
            } else {
                console.error('Data is missing')
            }
        } else {
            console.log(data); // Check the error object in the console
        }
    }, [isSuccess, data])

    useEffect(() => {
        if (isLoginError) {
            console.log('isLoginError'); // Check the error object in the console
        }
    }, [isLoginError, error]);

    useEffect(() => {
        auth?.userInfo && navigate('/')
    }, [navigate])

    const submitHandler = async (data) => {
        console.error('submitHandler:')

        try {
            await mutateAsync(data)
        } catch (error) {
            console.error('Login failed:', error)
            throw error; // Rethrow the error to be caught in the catch block
        }
    }

    return (
        <>
            <FormContainer>
                <HelmetProvider>
                    <Helmet>
                        <title>Login</title>
                        <meta property='og:title' content='Login' key='title'/>
                    </Helmet>
                </HelmetProvider>
                <h3 className='fw-light font-monospace text-center'>Sign In</h3>
                {data?.isError && <Message variant='danger'>{data?.error || 'An unexpected error occurred.'}</Message>}
                {isErrorUserRole && <Message variant='danger'>{errorUserRole.message}</Message>}

                <form onSubmit={handleSubmit(submitHandler)}>
                    {inputEmail({
                        register,
                        errors,
                        label: 'Email',
                        name: 'email',
                        placeholder: 'Email',
                    })}
                    {inputPassword({
                        register,
                        errors,
                        label: 'Password',
                        name: 'password',
                        placeholder: 'Password',
                    })}
                    <button
                        type='submit'
                        className='btn btn-primary form-control '
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <span className='spinner-border spinner-border-sm'/>
                        ) : (
                            <div>{t('sign_in')}</div>
                        )}
                    </button>
                </form>
                <div className='row pt-3'>
                    <div className='col'>
                        <Link to='/auth/forgot-password' className='ps-1'>
                            Forgot Password?
                        </Link>
                    </div>
                </div>
            </FormContainer>
        </>
    )
}

export default Login
