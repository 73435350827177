import React from 'react';
import "../../css/events-view.css";
import dynamicAPI from '../../api/dynamicAPI';
import {useQuery} from 'react-query';
import EventsList from "../../components/events/events-list";
import EventsListForm from "../../components/events/events-list-form";

function EventsAll() {
    const url = '/api/view/events';
    const q = '';
    const queryKey = 'Events';
    const {data: eventsData, error: eventsError} = useQuery(
        queryKey,
        async () =>
            await dynamicAPI('get', `${url}?page=1&q=${q}&limit=1000`, {}),
        {retry: 0}
    );
    // console.log('heyu');

    // console.log(eventsData);

    // console.log(spacesData);

    // Check if the data has been fetched successfully
    if (eventsError) {
        return <div>Error: {eventsError.message}</div>;
    }

    if (!eventsData || !eventsData.data || !Array.isArray(eventsData.data)) {
        return <div>No events found.</div>;
    }
    return (
        <>
            <title>Find what is going on in the city</title>
            <meta property="og:title" content="Events in the City" key="title"/>

            <div className="events-block" style={{ width: '100%'}}>
                    <EventsListForm />
                    <EventsList eventsData={eventsData} />
            </div>
        </>
    );
}

export default EventsAll;