import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as EnglishIcon} from "../../locales/en/en.svg";
import {ReactComponent as GreekIcon} from "../../locales/el/el.svg";
import "../../css/language-switcher.css";

function LanguageSwitcher() {
    const {i18n} = useTranslation();
    const [showDropdown, setShowDropdown] = useState(false);
    const languageOptions = [
        {value: 'en', label: 'English', icon: <EnglishIcon/>},
        {value: 'el', label: 'Ελληνικά', icon: <GreekIcon/>}
    ];
    const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[0].value);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setSelectedLanguage(language);
        setShowDropdown(false);
    };

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    return (
        <div className="language-selector">
            <div className="dropdown-container">
                <div className="selected-option" onClick={toggleDropdown}>
                    <div className="dropdown-option-icon">
                        {languageOptions.find((option) => option.value === selectedLanguage).icon}
                    </div>
                    <div className="dropdown-option-label">
                        {languageOptions.find((option) => option.value === selectedLanguage).label}
                    </div>
                </div>

                {showDropdown && (
                    <div className="options-container">
                        {languageOptions.map((option) => (
                            <div
                                key={option.value}
                                className="option"
                                onClick={() => changeLanguage(option.value)}
                            >
                                {option.icon}
                                {option.label}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default LanguageSwitcher;
