import { Helmet, HelmetProvider } from 'react-helmet-async'
import {Link} from "react-router-dom";
import React from "react";
const DefaultEventsIcon = window.location.origin + "/media/rabbits-404.png";

const NotFound = () => {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>404 - Not Found</title>
                    <meta property='og:title' content='404 - Not Found' key='title' />
                </Helmet>
            </HelmetProvider>
            <div
                className='d-flex justify-content-center align-items-center'
                style={{ height: '70vh', backgroundImage: `url(${DefaultEventsIcon})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}
            >
                <div className='notfound-text-block'>
                  <div className='notfound-big-text'>PAGE NOT FOUND</div>
                  <div className='notfound-text'>The rabbits have been nibbling the cables again</div>
                  <div className='notfound-text-link'>Maybe this will help
                                <Link to={"/"} className="go_home_link">
                                    Go Home
                                </Link>
                  </div>
                </div>
            </div>
        </>
    )
}

export default NotFound