import React, { useState, useRef } from "react";
import {getDefaultCategoryOptions} from './space-filtering';
import {useForm} from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import {GoogleCaptchaKey} from "../../config/client-config";
import {
    dynamicInputSelect,
} from '../../utils/dynamicForm';

import "../../css/space-form.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const apiUrl = process.env.REACT_APP_API_URL || "https://api.aart.gr";
const RECAPTCHA_SITE_KEY = GoogleCaptchaKey;


const categoryOptions = getDefaultCategoryOptions().map((option, index) => ({
    ...option,
    key: index.toString(),
}));

function SpaceForm() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();


    const [showForm, setShowForm] = useState(true);
    const [message, setMessage] = useState(null);
    const recaptchaRef = useRef(null);

    // Function to handle form submission
    const onSubmit = async (data) => {

        const recaptchaToken = await recaptchaRef.current.executeAsync();

        // Create the request options object for the POST request to the API
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({ recaptchaToken, data }),
        };

        // Send the POST request to the API using fetch
        const response = await fetch(apiUrl + '/api/submit/spaces', requestOptions);

        // Handle the response and handle any errors
        if (response.ok) {
            // Success
            setShowForm(false);
            const responseData = await response.json();
            setMessage(responseData.message || "Submitted but with some errors!");
            toast.success("Thank you for submitting this space, we will need sometime to verify it!");
            // Reset form values
            reset();
        } else {
            // Error
            const errorData = await response.json();
            setMessage(errorData.message || "An error occurred");
        }
    };

    const handleButtonClick = () => {
        setShowForm(!showForm);
    };
    const handleCloseButtonClick = () => {
        handleButtonClick();
    };
    return (
        <div
            className={
                showForm ? "space-form-container" : "space-form-container add-form"
            }
        >
            <div className="form-add-background-block"></div>

            <div className="form-add-button-block">
                <button className="add-form-button" onClick={handleButtonClick}>
                    Add A Space +
                </button>
            </div>

            <div className="space-form">
                <ToastContainer />
                <div className="space-form-block">
                    <div className="space-form-title">Νέα Αίτηση Καταγραφής</div>
                    <div className="space-form-close-button" onClick={handleCloseButtonClick}>X</div>

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="space-form-block-recaptcha">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey={RECAPTCHA_SITE_KEY}
                            />
                        </div>

                        <div>
                            <label htmlFor="name">Όνομα του χώρου</label>
                            <input
                                placeholder=""
                                type="text"
                                {...register("name", {required: true})}
                                maxLength={50}
                            />
                            {errors.name && <span className="submit-error-validation">Name is required</span>}
                        </div>

                        <div>
                            <label htmlFor="address">Διεύθυνση</label>
                            <input {...register("address", {required: true})}
                                   maxLength={300}/>
                            {errors.address && <span className="submit-error-validation">Address is required</span>}
                        </div>

                        <div>
                            <label htmlFor="description">Περιγραφή του Χώρου</label>
                            <textarea
                                placeholder="Nobody's Theater: A premier theatrical venue in the heart of the city, featuring a grand proscenium stage, modern amenities, and versatile spaces for captivating performances, concerts, and events."
                                {...register("description", {required: true})}
                                maxLength={500}
                                rows={5}
                                cols={50}
                            />
                            {errors.description && <span className="submit-error-validation">Description is required</span>}
                        </div>


                        {dynamicInputSelect({
                            register,
                            errors,
                            label: 'Category',
                            name: 'category',
                            placeholder: '',
                            isRequired: true,
                            data: categoryOptions,
                            value: 'label',
                            id: 'value',
                            media_path: '../media/map_icons/',
                            icons: true,
                        })}


                        <div>
                            <label htmlFor="glocation">Google Maps Location</label>
                            <input placeholder="https://goo.gl/maps/a5by2tSsL9dh3eRP6" {...register("glocation", {required: true})}
                                   maxLength={500}/>
                            {errors.glocation && <span className="submit-error-validation">Google Maps Location is required</span>}

                        </div>

                        {/*<div>*/}
                        {/*  <label htmlFor="email">Email</label>*/}
                        {/*  <input*/}
                        {/*    placeholder="info@google.com"*/}
                        {/*    type="email"*/}
                        {/*    {...register("email")}*/}
                        {/*  />*/}
                        {/*</div>*/}
                        <>
                            {message && <p className={message.includes("added") ? "success-message" : "error-message"}>{message}</p>}
                        </>
                        <input type="submit" value={"Καταχώρηση"}/>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SpaceForm;
