import React, {useState} from "react";
import L from "leaflet";
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    ZoomControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import "../../css/spaces-view.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import Card from "@mui/material/Card";
import {Button, CardActionArea, CardActions} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import MarkerClusterGroup from "@changey/react-leaflet-markercluster";
import CategoryFilter from "./space-filtering";
const media_path = window.location.origin;
const DefaultIcon = media_path+"/media/map_icons/default.svg";
const UnconfirmedIcon = media_path+"/media/map_icons/unconfirmed.svg";

// import Place from "../../types/places.type";

let markerclusterOptions = {
    disableClusteringAtZoom: 12,
    // maxClusterRadius: 100,
    spiderfyOnMaxZoom: false,
    animateAddingMarkers: true
};

function SpaceMapNew(props) {
    let DefaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
    });

    L.Marker.prototype.options.icon = DefaultIcon;
    const [showFilters, setShowFilters] = useState(false); // State variable for showing/hiding filters
    const [tileServerIndex, setTileServerIndex] = useState(0); // Start with the primary server

    const tileServers = [
        "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png", // Fallback 1: Carto Light, minimalistic
        "https://tile.openstreetmap.bzh/br/{z}/{x}/{y}.png", // Fallback 2: Brittany-specific, in Breton language
        "https://tile.openstreetmap.org/{z}/{x}/{y}.png", // Fallback 3: Standard OpenStreetMap tiles
        "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png", // Primary 4: HOT style by OSM France -- stopped working
        // Add more fallback servers as needed
    ];

    const handleTileError = () => {
        setTileServerIndex((currentIndex) =>
            currentIndex + 1 < tileServers.length ? currentIndex + 1 : 0 // Switch to next server or loop back
        );
    };

    // HERE WE LIMIT THE SCROLLING ON THE MAP
    // var southWest = L.latLng(40.453668, 22.972472),
    //     northEast = L.latLng(40.776838, 22.926328),
    //     bounds = L.latLngBounds(southWest, northEast);

    const greeceBounds = L.latLngBounds(
        L.latLng(34.5, 19.37), // Adjusted southwest corner to include Crete and Rhodes
        L.latLng(41.75, 26.35)  // Northeast corner remains the same
    );


    const THESSALONIKI_POSITION = [40.629269, 22.947412];

    const [selectedCategories, setselectedCategories] = useState([]);

    const filteredMarkers = props.generatePins.filter((pinData) => {
        if (!selectedCategories.length || selectedCategories.includes("no_category")) {
            return true; // Show all markers if no category is selected
        } else {
            // Check if any of the pin's categories are included in the selected categories
            return pinData.category.some(cat => selectedCategories.includes(cat));
        }
    });

    return (
        <div>
                <div className="space-filters-section" data-showfilters={showFilters}>
                    <div className="space-filters-title" onClick={() => setShowFilters(!showFilters)} style={{marginBottom: '10px'}}>
                        {showFilters ? 'Φίλτρα' : 'Φίλτρα'}
                    </div>
                    {showFilters && (

                        <div className="space-filters">
                            <div className="space-filters-block">
                                <CategoryFilter
                                    value={selectedCategories}
                                    onChange={(categoryValue) => setselectedCategories(categoryValue)}
                                />
                            </div>
                    </div>
                    )}
                </div>

            <MapContainer
                center={THESSALONIKI_POSITION}
                zoom={13}
                maxZoom={16}
                minZoom={8}
                scrollWheelZoom={true}
                zoomControl={false}
                maxBounds={greeceBounds} // Restrict panning to Greece's bounds
                maxBoundsViscosity={0.1} // Makes the bounds fully solid, preventing the user from dragging outside
            >
                <ZoomControl position="topleft"/>
                <TileLayer
                    attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>'
                    url={tileServers[tileServerIndex]}
                    eventHandlers={{
                        tileerror: handleTileError,
                    }}
                />
                <MarkerClusterGroup {...markerclusterOptions}>
                    {filteredMarkers.map((pinData) => {
                        // console.log(pinData);
                        return createMarker(pinData);
                    })}
                </MarkerClusterGroup>
            </MapContainer>
        </div>

    );
}

function mapIcon(category, confirmed) {
    if (!category) {
        return DefaultIcon;
    } else {
        if (confirmed === false) {
            return UnconfirmedIcon;
        } else {
            return media_path + "/media/map_icons/" + category + ".svg"
        }
    }
}

function createMarker(pinData) {

    // console.log(pinData);

    if (!pinData.lat || !pinData.lng || !pinData.name) {
        console.log(pinData);
    }

    const markerIcon = L.icon({
        iconUrl: mapIcon(pinData.category, pinData.confirmed ),
        iconAnchor: [12.5, 21], // Anchor point is at the bottom middle of the icon
        popupAnchor: [12.5, -21], // Adjusts the popup to open above the marker
    });

    return (
        <Marker
            position={[pinData.lat, pinData.lng]}
            key={pinData.name}
            icon={markerIcon}
        >
            <Popup maxWidth={300}>
                <Card className="place-block-popup" data-pinid={pinData.name}>
                    <CardActionArea className="place-block-popup-area">
                        <CardMedia
                            component="img"
                            image={
                                pinData.image
                            }
                        />
                        <CardContent className="place-block-data">
                            <Typography gutterBottom variant="h6" component="div" className="block-place-name">
                                {pinData.name}
                            </Typography>
                            <div className={"block-place-description-block"}>
                                <div className={"block-place-description-title"}>
                                    Περιγραφή:
                                </div>
                                <Typography variant="body2" color="text.secondary" className="block-place-description">
                                    {pinData.description}
                                </Typography>
                            </div>
                            <div className={"block-place-address-block"}>
                                <div className={"block-place-address-title"}>
                                    Διεύθυνση:
                                </div>
                                <Typography variant="body2" color="text.secondary" className="block-place-address">
                                    {pinData.address}
                                </Typography>
                            </div>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button size="small" color="primary">
                            <a href={pinData.website} target="_blank" rel="noopener noreferrer">
                                Website
                            </a>
                        </Button>
                    </CardActions>
                </Card>
            </Popup>
        </Marker>
    );
}

export default SpaceMapNew;
