import React, {useState, useEffect} from 'react'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {confirmAlert} from 'react-confirm-alert'
import {useForm} from 'react-hook-form'
import useSpacesHook from '../../../api/spaces'
import {ErrorBoundary} from "react-error-boundary";
import {useNavigate} from 'react-router-dom';

import {
    Spinner,
    ViewSpaces,
    Pagination,
    FormSpaces,
    Message,
    Confirm,
} from '../../../components'
import {Button} from "@mui/material";

const Spaces = () => {
    const [page, setPage] = useState(1)
    const [id, setId] = useState(null)
    const [edit, setEdit] = useState(false)
    const [q, setQ] = useState('')

    const {getSpaces, postSpace, updateSpace, deleteSpace} = useSpacesHook({
        page,
        q,
    })

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            confirmed: true,
            blocked: false,
        },
    })

    const {
        data,
        isLoading,
        isError,
        error,
        refetch
    } = getSpaces

    const {
        isLoading: isLoadingUpdate,
        isError: isErrorUpdate,
        error: errorUpdate,
        isSuccess: isSuccessUpdate,
        mutateAsync: mutateAsyncUpdate,
    } = updateSpace

    const {
        isLoading: isLoadingDelete,
        isError: isErrorDelete,
        error: errorDelete,
        isSuccess: isSuccessDelete,
        mutateAsync: mutateAsyncDelete,
    } = deleteSpace

    const {
        isLoading: isLoadingPost,
        isError: isErrorPost,
        error: errorPost,
        isSuccess: isSuccessPost,
        mutateAsync: mutateAsyncPost,
    } = postSpace

    const formCleanHandler = () => {
        if (isSuccessPost || isSuccessUpdate) {
            setEdit(false);
            reset();
        }
    }

    useEffect(() => {
        //if (isSuccessPost || isSuccessUpdate) formCleanHandler()
    }, [isSuccessPost, isSuccessUpdate])

    useEffect(() => {
        refetch()
    }, [page])

    useEffect(() => {
        if (!q) refetch()
    }, [q])

    const searchHandler = (e) => {
        e.preventDefault()
        refetch()
        setPage(1)
    }

    const deleteHandler = (id) => {
        confirmAlert(Confirm(() => mutateAsyncDelete(id)))
    }
    const navigate = useNavigate();

    const submitHandler = async (data) => {
        try {
            // console.log(data);
            if (edit) {
                await mutateAsyncUpdate({
                    _id: id,
                    name: data.name,
                    address: data.address,
                    description: data.description,
                    phone: data.phone,
                    postal: data.postal,
                    floor: data.floor,
                    glocation: data.glocation,
                    lat: data.lat,
                    lng: data.lng,
                    admin1: data.admin1,
                    note: data.note,
                    website: data.website,
                    image: data.image,
                    category: data.category,
                    tags: data.tags,
                    email: data.email,
                    confirmed: data.confirmed,
                    blocked: data.blocked
                });
            } else {
                await mutateAsyncPost(data);
            }

            // Request succeeded
            if (edit) {
                console.log('SUCCESSFULL EDIT POST');
            } else {
                console.log('SUCCESSFULL NEW POST');
            }
        } catch (error) {
            // Request failed
            console.error(error);
            if (edit) {
                console.log('FAILED EDIT POST')

            } else {
                console.log('FAILED NEW POST')
            }
            if (error.response && error.response.status === 500) {
                console.log('FAILED POST 500')
            }
        }
    };

    const editHandler = (space) => {
        setId(space._id)
        setEdit(true)
        setValue('name', space.name)
        setValue('address', space.address)
        setValue('description', space.description)
        setValue('phone', space.phone)
        setValue('postal', space.postal)
        setValue('floor', space.floor)
        setValue('glocation', space.glocation)
        setValue('lat', space.lat)
        setValue('lng', space.lng)
        setValue('admin1', space.admin1)
        setValue('note', space.note)
        setValue('website', space.website)
        setValue('image', space.image)
        setValue('category', space.category)
        setValue('tags', space.tags)
        setValue('email', space.email)
        setValue('confirmed', space.confirmed)
        setValue('blocked', space.blocked)
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Spaces</title>
                    <meta property='og:title' content='Spaces' key='title'/>
                </Helmet>
            </HelmetProvider>
            <ErrorBoundary
                FallbackComponent={() => (
                    <ErrorFallback goBack={() => navigate(-1)} error={isSuccessPost}/>
                )}
                onError={console.error}
            >
                {isErrorPost && <Message variant='danger'>{errorPost}</Message>}
                {isErrorUpdate && <Message variant='danger'>{errorUpdate}</Message>}

                {isSuccessDelete && (
                    <Message variant='success'>Space has been deleted successfully.</Message>
                )}
                {isErrorDelete && <Message variant='danger'>{errorDelete}</Message>}
                {isSuccessUpdate && (
                    <Message variant='success'>Space has been updated successfully.</Message>
                )}
                {isSuccessPost && (
                    <Message variant='success'>Space has been Created successfully.</Message>
                )}

                <FormSpaces
                    edit={edit}
                    formCleanHandler={formCleanHandler}
                    isLoading={isLoading}
                    isError={isError}
                    errors={errors}
                    isLoadingUpdate={isLoadingUpdate}
                    isLoadingPost={isLoadingPost}
                    register={register}
                    handleSubmit={handleSubmit}
                    submitHandler={submitHandler}
                    watch={watch}
                    error={error}
                    setValue={setValue}
                />

                <div className='ms-auto text-end'>
                    <Pagination data={data} setPage={setPage}/>
                </div>

                {isLoading ? (
                    <Spinner/>
                ) : isError ? (
                    <Message variant='danger'>{error}</Message>
                ) : (
                    <ViewSpaces
                        data={data}
                        editHandler={editHandler}
                        deleteHandler={deleteHandler}
                        formCleanHandler={formCleanHandler}
                        isLoadingDelete={isLoadingDelete}
                        setQ={setQ}
                        q={q}
                        searchHandler={searchHandler}
                    />
                )}
            </ErrorBoundary>

        </>
    )
}

function ErrorFallback({error, goBack}) {
    const errorMessage = error && error.message ? error.message : "Unknown error";

    return (
        <div>
            <div className="user-error-message-block">
                <p>Something went wrong 😭</p>
                <span>Error: {errorMessage}</span>
                <Button onClick={goBack}>Go Back</Button>
            </div>
        </div>
    );
}

export default Spaces
