import React, {useState} from 'react';
import {Grid} from "@mui/material";

const DefaultMarkerIcon = window.location.origin + "/media/event_icons/default.svg";

import EventsCategoryFilter, {getDefaultEventsCategoryOptions} from "./events-filtering";

function EventsList({eventsData}) {


    const [selectedCategory, setSelectedCategory] = useState('');

    function getImageSource(category) {
        if (category) {
            const domain = window.location.origin;
            return `${domain}/media/event_icons/${category}.svg`;
        }
        return DefaultMarkerIcon;
    }

    function isDateToday(date) {
        const today = new Date();
        const formattedDate = new Date(date);
        return (
            formattedDate.getDate() === today.getDate() &&
            formattedDate.getMonth() === today.getMonth() &&
            formattedDate.getFullYear() === today.getFullYear()
        );
    }

    function isDateTomorrow(date) {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const formattedDate = new Date(date);
        return (
            formattedDate.getDate() === tomorrow.getDate() &&
            formattedDate.getMonth() === tomorrow.getMonth() &&
            formattedDate.getFullYear() === tomorrow.getFullYear()
        );
    }

    function formatDate(date) {
        return date.toLocaleDateString(undefined, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    }

    function extractMonth(date) {
        return date.toLocaleDateString(undefined, { month: 'long' });
    }

    function extractWeekday(date) {
        return date.toLocaleDateString(undefined, { weekday: 'long', day: 'numeric' });
    }

    const groupedEvents = {};

    // Filter out events that have already passed
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const fifteenDaysFromNow = new Date();
    fifteenDaysFromNow.setDate(fifteenDaysFromNow.getDate() + 15);

    // Filter events based on the selected category and group them also sort them
    eventsData.data.forEach(event => {
        // if no category is selected or if it matches the selected category
        if (new Date(event.dateEnd) > yesterday && (selectedCategory == "" || selectedCategory.includes(event.category))) {
            let currentDate = new Date(event.dateStart);
            let endDate = new Date(event.dateEnd);

            // Trim dates that are outside the range
            if (currentDate < yesterday) {
                currentDate = new Date(yesterday);
            }
            if (endDate > fifteenDaysFromNow) {
                endDate = new Date(fifteenDaysFromNow);
            }

            while (currentDate <= endDate) {
                const formattedDate = formatDate(currentDate);
                const month = extractMonth(currentDate);
                const weekday = extractWeekday(currentDate);
                if (!groupedEvents[formattedDate]) {
                    groupedEvents[formattedDate] = {
                        events: [],
                        isToday: isDateToday(currentDate),
                        isTomorrow: isDateTomorrow(currentDate),
                        date: formattedDate,
                        month: month,
                        weekday: weekday
                    };
                }
                groupedEvents[formattedDate].events.push(event);
                currentDate.setDate(currentDate.getDate() + 1);
            }
        }
    });

    // Sort the array based on the date in each entry
    const groupedEventsArray = Object.entries(groupedEvents).sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB));


    function handleCategoryChange(category) {
        // console.log('AAAAAAAAAAAAAAAAATEST' + category)
        // Set selectedCategory to null if the category is an empty string
        setSelectedCategory(category);
    }

    return (
        <div>
            <div className="events-list-section">
                <Grid gridRow={1} sx={{width: '100%', height: '100%'}}>
                    <Grid sm={12}>
                        {groupedEventsArray.length === 0 ? (
                            <div className="events-list-block">
                                <div className="events-list-title">Events</div>
                                <div className="no-events-message">Is it possible for nothing to be happening in
                                    Thessaloniki, despite having 1 million registered people?
                                </div>
                            </div>
                        ) : (
                            groupedEventsArray.map(([date, {
                                events,
                                isToday,
                                isTomorrow,
                                weekday,
                                month
                            }]) => (
                                <div className="events-list-by-date" key={date}>
                                    <div className="events-list-date-title">
                                        <div className="event-month">{month}</div>
                                        <div className="event-day">{weekday}</div>
                                        {isToday && <div className="event-today">Σήμερα</div>}
                                        {isTomorrow && <div className="event-tomorrow">Αύριο</div>}
                                    </div>
                                    <div className="events-list-date-items">
                                        {events.map((event) => (
                                            <div className="events-list-item" key={event.id}>
                                                <img className="events-list-item-category-icon"
                                                     src={getImageSource(event.category)}
                                                     alt="Category Image"/>
                                                <div className="events-list-item-time">
                                                    {event.time}
                                                </div>
                                                <a className="events-list-item-link" href={event.link} target="_blank"
                                                   rel="noopener noreferrer">
                                                    {event.title}
                                                </a>
                                                {event.glocation && (
                                                    <a className="events-list-item-location" href={event.glocation}
                                                       target="_blank" rel="noopener noreferrer">
                                                        <img className="events-list-item-location-icon"
                                                             src={DefaultMarkerIcon} alt="Default Marker Icon"/>
                                                    </a>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))
                        )}
                    </Grid>
                    <Grid sm={12}></Grid>
                </Grid>
            </div>
            <div className="events-filters-section">
                <div className="events-filters-title">Φίλτρα</div>
                <div className="events-filters-block">
                    <EventsCategoryFilter
                        options={getDefaultEventsCategoryOptions()}
                        onChange={handleCategoryChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default EventsList;