import moment from 'moment'
import {FaPenAlt, FaTrash, FaCheckCircle, FaTimesCircle} from 'react-icons/fa'
import {Search} from '../../index'
import { format, isValid } from 'date-fns';

const DefaultEventsIcon = window.location.origin + "/media/calendar.svg";

export const ViewEvents = ({
                               data,
                               editHandler,
                               deleteHandler,
                               setEdit,
                               formCleanHandler,
                               isLoadingDelete,
                               setQ,
                               q,
                               searchHandler
                           }) => {
    // console.log(data);
    return (
        <div className='table-responsive bg-light p-3 mt-2'>
            <div className='d-flex align-items-center flex-column mb-2'>
                <h3 className='fw-light text-muted'>
                    Events List <sup className='fs-6'> [{data && data.total}] </sup>
                    <img className="events-list-title-icon" src={DefaultEventsIcon} alt="Default Marker Icon"/>
                </h3>
                <button
                    className='btn btn-outline-primary btn-sm shadow my-2'
                    data-bs-toggle='modal'
                    data-bs-target='#eventModal'
                    onClick={() => {
                        formCleanHandler();
                      setEdit(false);
                    }}
                >
                    Add New Event
                </button>
                <div className='col-auto'>
                    <Search
                        placeholder='Search by Title,Category'
                        setQ={setQ}
                        q={q}
                        searchHandler={searchHandler}
                    />
                </div>
            </div>
            <table className='table table-sm table-border edit-grid-view'>
                <thead className='border-0'>
                <tr>
                    <th>Created</th>
                    <th>Date</th>
                    <th>Title</th>
                    <th>Category</th>
                    <th>Link</th>
                    <th>Location</th>
                    <th>Confirmed</th>
                    <th>Blocked</th>
                    <th>Actions</th>
                </tr>
                </thead>

                <tbody>
                {data && data.data ? (
                    data.data.map((event) => (
                        <tr key={event._id}
                            className={(event.title === '' || event.date === '' || event.link === '') ? 'empty-values' : ''}>
                            <td>{moment(event.createdAt).format('lll')}</td>
                            {/*{                console.log(event)}*/}
                            <td>
                                {isValid(new Date(event.dateStart)) && isValid(new Date(event.dateEnd))
                                    ? format(new Date(event.dateStart), 'yyyy-MM-dd') + ' - ' + format(new Date(event.dateEnd), 'yyyy-MM-dd') + ' | ' + event.time
                                    : 'Invalid date'
                                }
                            </td>

                            <td>{event.title}</td>
                            <td>{event.category}</td>
                            <td>{event.link}</td>
                            <td>{event.glocation}</td>
                            <td>
                                {event.confirmed ? (
                                    <FaCheckCircle className='text-success'/>
                                ) : (
                                    <FaTimesCircle className='text-danger'/>
                                )}
                            </td>
                            <td>
                                {event.blocked ? (
                                    <FaCheckCircle className='text-success'/>
                                ) : (
                                    <FaTimesCircle className='text-danger'/>
                                )}
                            </td>

                            <td>
                                <div className='btn-group'>
                                    <button
                                        className='btn btn-primary btn-sm rounded-pill'
                                        onClick={() => editHandler(event)}
                                        data-bs-toggle='modal'
                                        data-bs-target='#eventModal'
                                    >
                                        <FaPenAlt/>
                                    </button>

                                    <button
                                        className='btn btn-danger btn-sm ms-1 rounded-pill'
                                        onClick={() => deleteHandler(event._id)}
                                        disabled={isLoadingDelete}
                                    >
                                        {isLoadingDelete ? (
                                            <span className='spinner-border spinner-border-sm'/>
                                        ) : (
                                            <span>
                          <FaTrash/>
                        </span>
                                        )}
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))) : (
                    // Render an error message when data is undefined or has no data
                    <tr>
                        <td colSpan="8">Error: Failed to fetch event data</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    )
}

export default ViewEvents
