import {useNavigate, Link, Routes, Route} from 'react-router-dom';
import RequireAuth from './components/shared/RequireAuth';
import ForgotPassword from './screens/auth/ForgotPassword';
import Home from './screens/auth/Home';
import Login from './screens/auth/Login';
import {Button} from "@mui/material";
// import MapsOld from './screens/maps/maps-page';
import Maps from './screens/maps/maps-page-new';
import NotFound from './screens/auth/NotFound';
import ResetPassword from './screens/auth/ResetPassword';
import Profile from './screens/account/Profile';
import ClientPermissions from './screens/admin/auth/ClientPermissions';
import Permissions from './screens/admin/auth/Permissions';
import Roles from './screens/admin/auth/Roles';
import UserRoles from './screens/admin/auth/UserRoles';
import UserProfiles from './screens/admin/auth/UserProfiles';
import Users from './screens/admin/auth/Users';
import Spaces from './screens/admin/auth/Spaces';
import Events from './screens/admin/auth/Events';
import AllEvents from './screens/events/events-all';
import {Layout} from './components';
import React from "react";

import {ErrorBoundary} from "react-error-boundary";

const App = () => {
    const navigate = useNavigate();

    return (
        <>
            <ErrorBoundary
                FallbackComponent={() => (
                    <ErrorFallback goBack={() => navigate(-1)}/>
                )}
                onError={console.error}
            >


                <Layout showAdminNavigation={true}>
                    <Routes>
                        {/* OPEN ROUTES*/}
                        <Route path='/' element={<Home/>}/>
                        <Route path='/events/all' element={<AllEvents/>}/>
                        <Route path='/maps/spaces' element={<Maps/>}/>
                        {/*<Route path='/maps/spacesall' element={<MapsOld />} />*/}
                        <Route path='/auth/login' element={<Login/>}/>
                        <Route path='/auth/forgot-password' element={<ForgotPassword/>}/>
                        <Route path='/auth/reset-password/:restToken' element={<ResetPassword/>} />

                        {/* AUTH ROUTES*/}
                        <Route element={<RequireAuth/>}>
                            <Route path='/account/profile' element={<Profile/>}/>
                            <Route path='/admin/auth/client-permissions' element={<ClientPermissions/>}/>
                            <Route path='/admin/auth/permissions' element={<Permissions/>}/>
                            <Route path='/admin/auth/roles' element={<Roles/>}/>
                            <Route path='/admin/auth/user-roles' element={<UserRoles/>}/>
                            <Route path='/admin/auth/user-profiles' element={<UserProfiles/>}/>
                            <Route path='/admin/auth/users' element={<Users/>}/>
                            <Route path='/admin/auth/spaces' element={<Spaces/>}/>
                            <Route path='/admin/auth/events' element={<Events/>}/>
                        </Route>

                        <Route path='*' element={<NotFound/>}/>
                    </Routes>
                </Layout>
            </ErrorBoundary>
        </>
    )
}

function ErrorFallback({error, goBack}) {
    const errorMessage = error && error.message ? error.message : "Unknown error";
    // const isDebugMode = process.env.NODE_ENV === 'development';
    const errorStack = error && error.stack ? error.stack : "No stack trace";

    return (

        <div className="main-user-error-message-block">
            <div className="user-error-message-block">
                <p>Something went wrong 😭</p>
                <span>Error: {errorMessage}</span>
                {console.log(errorStack)}
                {/*{isDebugMode && (*/}
                {/*    <>*/}
                {/*        <p>Stack Trace:</p>*/}
                {/*        <pre>{errorStack}</pre>*/}
                {/*    </>*/}
                {/*)}*/}
                <p>-</p>
                <p>
                    <Link to={"/"} className="go_to_start_link">
                        GO TO SHOP
                    </Link>
                </p>
                <p>
                    <Button onClick={goBack}>Go Back</Button>
                </p>
            </div>
        </div>
    );
}

export default App
