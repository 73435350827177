import React from 'react'
import ReactDOM from 'react-dom/client'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import './css/index.css'
import App from './App'
// import { I18nextProvider } from 'react-i18next';
import "./utils/i18n";

import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import {AuthProvider} from './context/AuthProvider'

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>

        <QueryClientProvider client={queryClient}>
                {/*<I18nextProvider i18n={i18n}>*/}
                <BrowserRouter>
                    <AuthProvider>
                        <Routes>
                            <Route path='/*' element={<App/>}/>
                        </Routes>
                    </AuthProvider>
                </BrowserRouter>
                {/*</I18nextProvider>*/}

            <ReactQueryDevtools/>

        </QueryClientProvider>
    </React.StrictMode>
)